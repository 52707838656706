import React, { Component } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { connect } from "react-redux";
import { properties } from '../properties.js';

const URL_ROI = `${properties.HOST}/settings`;

class SellerSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: undefined,
      roi: '',
    };
  }

  componentDidMount() {
    const user = this.props.user;
    console.log("User ",user);
    if (user) {
      this.setState({ currentUser: user });

      fetch(`${URL_ROI}/${user.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ roi: data.roi });
        })
        .catch((error) => {
          console.error('Error fetching ROI:', error);
        });
    }
  }

  handleRoiChange = (event) => {
    this.setState({ roi: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log('Updated ROI:', this.state.roi);
    // Handle the form submission logic here
  };

  render() {
    const { currentUser, roi } = this.state;
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" gutterBottom>
          Seller Settings
        </Typography>
        {currentUser && (
          <Typography variant="subtitle1" gutterBottom>
            Email: {currentUser.email}
          </Typography>
        )}
        <Typography variant="body1" gutterBottom>
          Configure the ROI to receive notifications for products under that value.
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <TextField
            id="roi"
            label="ROI"
            type="number"
            value={roi}
            onChange={this.handleRoiChange}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" type="submit">
            Update ROI
          </Button>
        </form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(SellerSettings);
