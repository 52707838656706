
import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, InputGroup } from '@themesberg/react-bootstrap';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { Loading } from '../pages/tables/theme-sources/material-ui/components/loading';
import Paper from '@mui/material/Paper';
import { Alert, AlertTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Importer, ImporterField } from "react-csv-importer";
// theme CSS for React CSV Importer
import "react-csv-importer/dist/index.css";
import { Architecture } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import { properties } from '../properties.js';
import { v4 as uuidv4 } from 'uuid';

const URL_UPLOAD_GENERATE_REPORT_RECORD = `${properties.HOST}/v2/reports/generate`;
const URL_UPLOAD_CSV_FILE = `${properties.HOST}/multipart-file/upload-filePart`;
const URL_CREATE_BATCH = `${properties.HOST}/v2/products/create-product-batch`;
const https = require('https');

export const GeneralInfoForm = () => {
  const [file, setFile] = useState()
  const [successUpload, setSuccessUpload] = useState(false);
  const [faileUpload, setFaileUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadId, setUploadId] = useState();
  const uploadIdGenerated = uuidv4();
  const [message, setMessage] = useState(null);

  const sendReport = () => {
    const httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
  
    const user = JSON.parse(localStorage.getItem("user"));
    let URL_GENERATE_REPORT = `${URL_UPLOAD_GENERATE_REPORT_RECORD}?userId=${user.id}&uploadId=${uploadIdGenerated}`;
  
    // Fetch call to backend
    fetch(URL_GENERATE_REPORT, {
      method: 'POST', 
      agent: httpsAgent,
    })
    .then(response => response.json())
    .then(data => {
      console.log("Report generated");
    })
    .catch(error => {
      console.error("Error: ", error);
    });
  };

  
  // TODO: Create function to send file to backend
  const handleFileUpload = (e) => {
    setFile(e.target.files[0])
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    setLoading(true);
    axios.post(URL_UPLOAD_CSV_FILE, formData, config).then((response) => {
      setSuccessUpload(true);
      setLoading(false);
    }).catch(function (error) {
      console.log(file);
      console.log(error);
      setFaileUpload(true);
      setLoading(false);
    });
  }

  return (
    <Paper>
      <Card border="light" className="bg-white shadow-sm mb-4" >
        <Card.Body >
          <h4>Import from csv file</h4>

          <Importer
            chunkSize={300} // optional, internal parsing chunk size in bytes
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            restartable={false} // optional, lets user choose to upload another file when import is complete
            onStart={({ file, fields }) => {
              // optional, invoked when user has mapped columns and started import
              console.log("starting import of file", file, "with fields", fields);
            }}
            processChunk={async (rows) => {
              const httpsAgent = new https.Agent({
                rejectUnauthorized: false,
              });
              // required, receives a list of parsed objects based on defined fields and user column mapping;
              // may be called several times if file is large
              // (if this callback returns a promise, the widget will wait for it before parsing more data)
              console.log("Received batch of rows==================", rows);
              console.log("GENERATED UUID ", uploadIdGenerated);
              const user = JSON.parse(localStorage.getItem("user"));
              var employees = [];
              var invalidRows = [];
              for (var i = 0; i < rows.length; i++) {
                if ((rows[i].asin == undefined || rows[i].asin == '')) {
                  invalidRows.push(i);
                  continue;
                }
                var employee = {
                  asin: rows[i].asin,
                  supplier: rows[i].supplier ? rows[i].supplier : "-",
                  supplierLink: rows[i].supplierLink ? rows[i].supplierLink : "-",
                  buyCost: rows[i].buyCost ? parseFloat(rows[i].buyCost.replace('$', '')) : 0.0,
                };
                employees.push(employee);
              }
              let URL_ADD_CREATION_BATCH_USERNAMR = `${URL_CREATE_BATCH}?username=${user.username}&uploadId=${uploadIdGenerated}`;
              const chunkSize = 1000; // Set the size of each chunk
              for (let i = 0; i < employees.length; i += chunkSize) {
                const chunk = employees.slice(i, i + chunkSize);
                console.log('URL FOR BATCH ', URL_ADD_CREATION_BATCH_USERNAMR);
                let body = JSON.stringify(chunk);
                console.log('BODY FOR BATCH ', body);

              new Promise((resolve, reject) => {
                fetch(URL_ADD_CREATION_BATCH_USERNAMR, {
                  method: 'POST', // or 'PUT'
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: body,
                  agent: httpsAgent,
                })
                  .then((data) => {
                    if (data.status == 400) {
                      console.log('Error adding products by csv');
                    } else {
                      console.log('Success adding products by csv');
                      setTimeout(resolve, 1000);
                    }
                  })
                  .catch((e) => {
                    console.log('Error adding products by csv', e);
                    setTimeout(resolve, 1000);
                  });
              }).then(data => console.log('FINISH LOADING DATA'))              }               
            }}
            onComplete={({ file, fields }) => {
              // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
              console.log("finished import of file", file, "with fields", fields);
              setMessage('Hang tight! We are processing your file and will shoot you an email when we are done.');
              console.log("importer dismissed");
              sendReport();
              console.log("Report generated for user");
            }}
            onClose={() => {
              // optional, invoked when import is done and user clicked "Finish"
              // (if this is not specified, the widget lets the user upload another file)
            }}
          >
            <ImporterField name="asin" label="Asin" />
            <ImporterField name="supplier" label="Supplier" />
            <ImporterField name="supplierLink" label="Supplier link" />
            <ImporterField name="buyCost" label="Buy Cost" />
          </Importer>
        </Card.Body>
      </Card>
      {message && (
        <Alert severity="info" sx={{ mt: 2 }}>
          {message}
        </Alert>
      )}
    </Paper>
  );
};
